<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header />

      <div class="clearfix"></div>

      <section class="bg-light pt-4">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="filter_search_opt">
                <a href="#" @click.prevent="openFilterSearch"
                  >My Account Navigation<i class="ml-2 ti-menu"></i
                ></a>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 col-md-12">
              <div class="simple-sidebar sm-sidebar" id="filter_search">
                <div class="search-sidebar_header">
                  <h4 class="ssh_heading">Close</h4>
                  <button
                    @click.prevent="closeFilterSearch"
                    class="w3-bar-item w3-button w3-large"
                  >
                    <i class="ti-close"></i>
                  </button>
                </div>

                <div class="sidebar-widgets">
                  <div class="dashboard-navbar">
                    <div class="d-user-avater">
                      <img
                        :src="user.avatar"
                        class="img-fluid avater"
                        :alt="user.name"
                        style="max-height: 150px"
                      />
                      <h4>{{ user.name }}</h4>
                    </div>

                    <div class="d-navigation">
                      <ul>
                        <li
                          :class="
                            $route.name == 'my-account.home' ? 'active' : ''
                          "
                        >
                          <router-link :to="{ name: 'my-account.home' }"
                            ><i class="ti-dashboard"></i>Dashboard</router-link
                          >
                        </li>
                        <li
                          :class="
                            $route.name == 'my-account.profile' ? 'active' : ''
                          "
                        >
                          <router-link :to="{ name: 'my-account.profile' }"
                            ><i class="ti-user"></i>My Profile</router-link
                          >
                        </li>
                        <li
                          :class="
                            $route.name == 'my-account.messages' ? 'active' : ''
                          "
                        >
                          <router-link :to="{ name: 'my-account.messages' }"
                            ><i class="ti-envelope"></i>My Messages</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="{ name: 'my-listings.index' }"
                            ><i class="ti-layers"></i>My Properties</router-link
                          >
                        </li>
                        <li
                          v-if="is_field_assistant"
                          :class="
                            $route.name == 'my-account.field-assistants.index'
                              ? 'active'
                              : ''
                          "
                        >
                          <router-link
                            :to="{ name: 'my-account.field-assistants.index' }"
                            ><i class="lni lni-users"></i>Field Assistant
                            Requests</router-link
                          >
                        </li>
                        <li
                          :class="
                            $route.name == 'my-account.earnings.index'
                              ? 'active'
                              : ''
                          "
                        >
                          <router-link
                            :to="{ name: 'my-account.earnings.index' }"
                            ><i class="lni lni-dollar"></i>My
                            Earnings</router-link
                          >
                        </li>
                        <li
                          :class="
                            $route.name == 'my-account.agencies.index'
                              ? 'active'
                              : ''
                          "
                        >
                          <router-link
                            :to="{ name: 'my-account.agencies.index' }"
                            ><i class="lni lni-home"></i>My
                            Agencies</router-link
                          >
                        </li>
                        <li
                          :class="
                            $route.name == 'my-account.bookmarks'
                              ? 'active'
                              : ''
                          "
                        >
                          <router-link :to="{ name: 'my-account.bookmarks' }"
                            ><i class="ti-bookmark"></i>Saved
                            Listings</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="{ name: 'my-account.customers' }"
                            ><i class="lni lni-users"></i>My Customers</router-link
                          >
                        </li>
                        <li>
                          <router-link :to="{ name: 'submit-property' }"
                            ><i class="ti-pencil-alt"></i>Submit New
                            Property</router-link
                          >
                        </li>
                        <li
                          :class="
                            $route.name == 'my-account.password' ? 'active' : ''
                          "
                        >
                          <router-link :to="{ name: 'my-account.password' }"
                            ><i class="ti-unlock"></i>Change
                            Password</router-link
                          >
                        </li>
                        <li>
                          <a href="#" @click.prevent="logout"
                            ><i class="ti-power-off"></i>Log Out</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-9 col-md-12">
              <router-view />
            </div>
          </div>
        </div>
      </section>

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  mounted() {
    if (!this.$auth.hasToken()) {
      window.location.href = this.$router.resolve({ name: "home" }).href;
    }
  },

  methods: {
    logout() {
      this.axios.post("/api/v1/logout").then(() => {
        this.$auth.removeToken();

        window.location.href = this.$router.resolve({ name: "login" }).href;
      });
    },

    openFilterSearch() {
      document.getElementById("filter_search").style.display = "block";
    },

    closeFilterSearch() {
      document.getElementById("filter_search").style.display = "none";
    },
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser;
    },

    is_field_assistant() {
      return (
        this.$store.getters.getAuthUser.services.indexOf("field-assistant") >= 0
      );
    },

    route() {
      return this.$route.fullPath
    }
  },

  watch: {
    route() {
      document.getElementById("filter_search").style.display = "none";
    }
  }
};
</script>
